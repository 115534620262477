import React, { useEffect, useState } from "react";
import { BsPersonCircle } from "react-icons/bs";
import { BiLogOut } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { authActions } from "../redux/Athentication/AuthSlice";
import { Switch } from "antd";
import { Baseurl } from "../config/BaseUrl";
import axios from "axios";
import { Modal,Button } from "antd";

const Header = () => {
  const { isAuth, name } = useSelector((store) => store.auth);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [status, setStatus] = useState(false);
  const showModal = (data) => {
    setIsModalOpen(true);
    setStatus(data)
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [closeOrderStatus, setCloseOrderStatus] = useState("");
  useEffect(() => {
    const getStatus = async () => {
      const url = `${Baseurl}/api/v1/closeorder/all`;
      const resp = await axios.get(url);
      if (resp.data.success) {
        setCloseOrderStatus(resp.data.orderStatus);
      }
    };
    getStatus();
  }, []);

  const handleStatus = async (status) => {
    const formData = {
      status: !closeOrderStatus.status,
    };
    const config = {
      Headers: { "Content-Type": "application/json" },
      maxBodyLength: Infinity,
    };
    const url = `${Baseurl}/api/v1/closeorder/update/66d55abd6cc7fbb18674e003`;
    console.log(formData, "formData");
    const resp = await axios.put(url, formData, config);
    console.log(resp.data, "resp.data");
    if (resp.data.success) {
      console.log(resp.data.closeOrder, "resp.data.orderStatus");
      setCloseOrderStatus(resp.data.closeOrder);
      setIsModalOpen(false);
      // window.location.reload();
    }
  };
  console.log(closeOrderStatus, "closeOrderStatus");

  const signout = () => {
    dispatch(authActions.signout());
    navigate("/");
  };
  return (
    <div>
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        {/* Left navbar links */}
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" data-widget="pushmenu">
              <i className="fas fa-bars" />
            </a>
          </li>
        </ul>
        <Modal
          title="Order Close Status"
          open={isModalOpen}
          onCancel={handleCancel}
          footer={[
            <Button
              style={{
                padding: "10px 15px",
                color: "#fff",
                backgroundColor: "#FF0000",
              }}
              onClick={() => handleCancel(false)}
            >
              Cancel
            </Button>,
            <Button
              style={{
                padding: "10px 15px",
                color: "#fff",
                backgroundColor: "#04681e",
              }}
              onClick={(e) => handleStatus(status)}
            >
              Confirm
            </Button>,
          ]}
        >
          {closeOrderStatus.status === true ? (
            <p>By turning off it you will start receiving orders</p>
          ) : (
            <p>By turning on it you won't be able to accept order</p>
          )}
        </Modal>
        <ul className="headerDesign">
          {/* <Link to="/admin/register">
            <li>
              <span className="m-2 mt-2">New Registation</span>
            </li>
          </Link> */}
          <li>
            <span style={{ fontStyle: "12px" }}>
              {/* <CgMaximizeAlt /> */}
              <Switch
                value={closeOrderStatus?.status === true ? true : false}
                onChange={() => showModal(closeOrderStatus?.status)}
              />{" "}
              Close Order
            </span>
          </li>
          {isAuth ? (
            <li onClick={() => signout()}>
              <span>
                <BiLogOut />
              </span>
              <span className="m-2 mt-2">{name}</span>
            </li>
          ) : (
            <Link to="/login">
              <li>
                <span>
                  <BsPersonCircle />
                </span>
              </li>
            </Link>
          )}
        </ul>
      </nav>
    </div>
  );
};
export default Header;
